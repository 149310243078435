@tailwind base;
@tailwind components;
@tailwind utilities;

  /* @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600;700;800&display=swap'); */
  @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;600;700&display=swap');
/* body {
  margin: 0;
  font-family: 'Roboto Slab', serif;
  -webkit-font-smoothing: 'Roboto Slab', serif;
  -moz-osx-font-smoothing: 'Roboto Slab', serif;
} */
*{
  font-family: 'Noto Serif JP', serif;
}




