*{font-family: 'Exo 2', sans-serif;
    font-family: 'Hanuman', serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Roboto', sans-serif;}



    .buyNowBtn:hover{
        opacity: .8;
        transition: .3s;
    }


    
/* css for sweet alert popup */
@media (max-width: 700px) {
    .swal2-popup{
        max-width: 100vw !important;
    }
}