/* paricing card */
.card-service-name{
background:linear-gradient(225deg, #FFA301 0%, #F44 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.tab-btn{
    background: linear-gradient(to left bottom, #ffa201 0%, rgba(255, 68, 68, 0.967) 100%);
}
.card-head{
    background: url("../../asstes/pricingshap.png") no-repeat left bottom, linear-gradient(180deg, #FFA301 0%, #F44 100%);
    background-size: 100%;
}
/* tabs-main border */
/* .tabs-main{
    border-image: linear-gradient(to right , hsla(0, 100%, 63%, 0.553), #ffa2014f) 30;
} */
/*  service box */
.serviceBox{
    background: url("../../asstes/servicebg.png") no-repeat bottom right #F0F0F0;
    transition: all .3s;
}
.serviceBox:hover{
    background: url("../../asstes/servicebghover.png") no-repeat top left ,linear-gradient(#ffa201df,#ff4444ec);
    color: white;
}
    /* support */
/* .support-bg{
    background: rgb(255,68,68);
    background: linear-gradient(50deg, rgba(255,68,68,1) 13%, #FF7D1B  60%, rgba(255,255,255,1) 80%, #FF7D1B  100%);
    } */


 /* faq accordion */
 .ReactCollapse--collapse {
        transition: height 500ms;
        font-size: 15px;
      }
 /* testimonial carousel */
 .testimonial .slick-slide{
    margin: 90px 0 !important;
 }
 .testimonial .carousel-div{
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);  
 }
 .testimonial .slick-next:before {
    content: '' !important;
    color: black !important;
    padding: 20px !important;
    background: url("../../asstes/arrow-right.png") white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-size: 0 !important;
    opacity: 1 !important;
    border-radius: 50%;
    box-shadow: 0 0 20px  #8080809f;
}
.testimonial .slick-prev:before{
    content: '' !important;
    color: black !important;
    padding: 20px !important;
    background: url("../../asstes/arrow-left.png") white;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 0 !important;
    background-position: center;
    opacity: 1 !important;
    border-radius: 50%;
    box-shadow: 0 0 20px #8080809f;
    margin-left: -15px;
}
.testimonial .slider-imgDiv{
    box-shadow: 0 -20px 20px #cccccc60;
    position: relative;
}
.panel-slider .slick-arrow{
    display: none !important;
}

/* @media screen and (max-width:768px) {
.support-bg{
    background: rgb(255,68,68);
    background: linear-gradient(50deg, rgba(255,68,68,1) 34%, #FF7D1B 46%, rgba(255,255,255,1) 66%, #FF7D1B 100%);
    }
    
} */

@media (max-width:400px) {
    .testimonial .slick-prev, .testimonial .slick-next {
        display: none !important;    }
    
    .testimonial .carousel-div{
        box-shadow: 0 0 0  #00000033; 
        /* border: 1px solid #00000033; */
        box-shadow: 0 0 10px #00000033;
     }
     .testimonial-container{
        padding: 0;
     }
}